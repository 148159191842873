import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'org-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'org';

    banners = [
        '/upload/iblock/banners/Main_banner_CMP.jpg',
        '/upload/iblock/banners/Main_banner_tucutuc.jpg',
    ]

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.translateService.use('en');
    }

    changeLang(location: string) {
        this.translateService.use(location)
    }
}
