<input type="hidden" id="current_landing_id" name="current_landing_id" value="153">

<a class="menu-slide-close tone-light" data-button="menu-slide-close"></a>


<div class="slide-menu tone-light">
    <div class="inner" style="min-height: 907px;">


        <div class="head-wrap">
            <div class="head-table">

                <div class="head-cell logotype">
                    <a href="#body" data-button="close-from-menu" class="scroll"><img src="/assets/sb.png" alt=""
                                                                                      class="img-responsive"></a>
                </div>


            </div>
        </div>

        <div class="menu-content no-margin-top-bot ">

            <ul class="nav">


                <li>


                    <a href="#block1101" class="scroll"
                       data-button="close-from-menu"><span>{{'menu.partners' | translate}}</span></a>

                </li>


                <li>


                    <a href="#block1105" class="scroll"
                       data-button="close-from-menu"><span>{{'menu.brands' | translate}}</span></a>

                </li>


                <li>


                    <a href="#block1081" class="scroll"
                       data-button="close-from-menu"><span>{{'menu.offer' | translate}}</span></a>

                </li>


                <li>


                    <a href="#block1131" class="scroll"
                       data-button="close-from-menu"><span>{{'menu.aboutUs' | translate}}</span></a>

                </li>


                <li>


                    <a href="#block1088" class="scroll"
                       data-button="close-from-menu"><span>{{'menu.contacts' | translate}}</span></a>

                </li>


                <li><a (click)="changeLang('en')" class="scroll" data-button="close-from-menu">
                    <span>{{'menu.englishVersion' | translate}}</span>
                </a></li>
                <li><a (click)="changeLang('ru')" class="scroll" data-button="close-from-menu">
                    <span>{{'menu.russianVersion' | translate}}</span>
                </a></li>
            </ul>

        </div>

        <div class="foot-wrap">
            <div class="foot-inner">

                <div class="part-cell left">
                    <a class="button-def primary big form elips" data-header="	Шапка сайта (меню)"
                       data-target=".callback-form" data-toggle="modal">Call back</a>
                </div>

                <div class="part-cell right">
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div class="number main1">
                                    +7 495 120 34 34
                                </div>


                                <div class="email">
                                    <a href="mailto:info@superbrands.ru">info@superbrands.ru</a>
                                </div>
                            </td>

                        </tr>
                        </tbody>
                    </table>

                </div>


            </div>


        </div>

    </div>

</div>


<div id="xLoader">
    <div class="google-spin-wrapper">
        <div class="google-spin"></div>
    </div>
</div>


<div class="wrapper">


    <header class="small slide type-1  menu-open tone-light"
            style="background-color: rgba(255,255,255, 1);background-image: url(&#39;&#39;); background-position: top center; background-repeat: no-repeat;;">


        <div class="scroll-wrap">

            <div class="container">
                <div class="row">


                    <div class="header-block header-table">

                        <div class="header-cell col-lg-4 col-md-4 col-sm-4 col-xs-3 left">
                            <div class="row">

                                <table>
                                    <tbody>
                                    <tr>
                                        <td class="visible-xs">
                                            <a class="menu-link primary" data-button="menu-slide-open"></a>
                                        </td>


                                    </tr>
                                    </tbody>
                                </table>


                            </div>

                        </div>


                        <div class="header-cell col-lg-4 col-md-4 col-sm-4 col-xs-6 center">
                            <div class="row">


                                <table>
                                    <tbody>
                                    <tr>


                                        <td class="logotype">
                                            <a class="scroll" href="#body">

                                                <img src="/assets/sb(1).png" class="img-responsive">
                                            </a>
                                        </td>


                                    </tr>
                                    </tbody>
                                </table>


                            </div>

                        </div>

                        <div class="header-cell col-lg-4 col-md-4 col-sm-4 col-xs-3 right">
                            <div class="row">

                                <table class="right-inner">
                                    <tbody>
                                    <tr>
                                        <td class="hidden-xs">
                                            <div class="main-phone">


                                                <div class="element phone main1">+7 495 120 34 34</div>


                                                <div class="comment">

                                                    <a class="mail" href="mailto:info@superbrands.ru">info@superbrands.ru</a>

                                                </div>


                                                <div class="ic-open-list-contact open-list-contact"><span></span></div>

                                                <div class="list-contacts">
                                                    <table>


                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="phone bold">+7 495 120 34 34</div>

                                                                <div class="desc">{{'address' | translate}}</div>
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <td>

                                                                <div class="email">
                                                                    <a href="mailto:info@superbrands.ru">info@superbrands.ru</a>
                                                                </div>

                                                                <div class="desc">Office</div>
                                                            </td>
                                                        </tr>


                                                        </tbody>
                                                    </table>


                                                </div>


                                            </div>


                                        </td>


                                        <td class="hidden-xs">

                                            <a class="callback form primary" data-target=".callback-form"
                                               data-header="Шапка сайта (меню)" data-toggle="modal"></a>

                                        </td>


                                        <td class="no-margin-top-bot tel-num visible-xs">

                                            <div class="number main1 mainColor">

                                                <a class="" data-button="wind-modal" data-target=".wind-modalphones"
                                                   data-toggle="modal"></a>
                                            </div>


                                        </td>


                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>


                    </div>

                    <div class="description visible-xs"></div>

                </div>
            </div>


            <div class="menu-type2 hidden-xs  active ln" style="border-bottom: 2px solid rgba(255,255,255, 0.2);">

                <div class="container">

                    <div style="width: 115px;"></div>
                    <div class="menu-type3   ">

                        <div class="nav-wrap clearfix">

                            <table class="wrap-main-menu">
                                <tbody>
                                <tr>
                                    <td>
                                        <div class="burger" style=""><a data-button="menu-slide-open"></a></div>
                                    </td>
                                    <td>
                                        <ul class="nav main-menu-nav table-ouch">


                                            <li class="lvl1  visible" id="element1101" style="">

                                                <a href="#block1101" class="scroll">{{'menu.partners' | translate}}</a>

                                            </li>


                                            <li class="lvl1 visible dropdown-menu-trigger" id="element1105" style="">

                                                <a href="#block1105" class="scroll">{{'menu.brands' | translate}}</a>

                                                <div class="dropdown-menu">
                                                    <div class="column">
                                                        <p class="header">Kids</p>
                                                        <ul>
                                                            <li><a href="#block1127"
                                                                   data-img="/content/media/brands_logos/trybeyond.jpg">Trybeyond</a>
                                                            </li>
                                                            <li><a href="#block1127"
                                                                   data-img="/content/media/brands_logos/birba.jpg">Birba</a>
                                                            </li>
                                                            <li><a href="#block1136"
                                                                   data-img="/content/media/brands_logos/tuctuc.jpg">TUC
                                                                TUC</a></li>
                                                            <!--<li><a href="#block1138" data-img="/content/media/brands_logos/canadahouse.jpg">Canada House</a></li>-->
                                                        </ul>
                                                    </div>
                                                    <div class="column">
                                                        <p class="header">Shoes</p>
                                                        <ul>
                                                            <li><a href="#block1109"
                                                                   data-img="/content/media/brands_logos/viking.png">Viking</a>
                                                            </li>
                                                            <li><a href="#block1105"
                                                                   data-img="/content/media/brands_logos/kickers.jpg">Kickers</a>
                                                            </li>
                                                            <li><a href="#block1107"
                                                                   data-img="/content/media/brands_logos/BLACK_COUGAR_logo_BW_Waterproof-EN.png">Cougar</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="column">
                                                        <p class="header">Fashion</p>
                                                        <ul>
                                                            <li><a href="#block1112"
                                                                   data-img="/content/media/brands_logos/boxeur.jpg">Boxeur
                                                                Des Rues</a></li>
                                                            <li><a href="#block1118"
                                                                   data-img="/content/media/brands_logos/cmp.jpg">CMP</a>
                                                            </li>
                                                            <li><a href="#block1122"
                                                                   data-img="/content/media/brands_logos/hype logo.jpg">Hype</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="column">
                                                        <p class="header">Sport/outdoor</p>
                                                        <ul>
                                                            <li><a href="#block1118"
                                                                   data-img="/content/media/brands_logos/cmp.jpg">CMP</a>
                                                            </li>
                                                            <li><a href="#block1120"
                                                                   data-img="/content/media/brands_logos/camelbak.jpg">Camelbak</a>
                                                            </li>
                                                            <!--<li><a href="#block1122" data-img="/content/media/brands_logos/aku.jpg">Aku</a></li>-->
                                                            <li><a href="#block1143"
                                                                   data-img="/content/media/brands_logos/robens.png">Robens</a>
                                                            </li>
                                                            <li><a href="#block1143"
                                                                   data-img="/content/media/brands_logos/easy_camp.png">Easy
                                                                Camp</a></li>
                                                            <li><a href="#block1143"
                                                                   data-img="/content/media/brands_logos/outwell.png">Outwell</a>
                                                            </li>

                                                        </ul>
                                                    </div>

                                                    <div class="image">
                                                        <img alt="" class="brand-image"
                                                             src="/assets/super_brands_logo_black_round.png">
                                                    </div>
                                                </div>
                                            </li>


                                            <li class="lvl1  visible" id="element1081" style="">

                                                <a href="#block1081" class="scroll">{{'menu.offer' | translate}}</a>

                                            </li>


                                            <li class="lvl1  visible" id="element1131" style="">

                                                <a href="#block1131" class="scroll">{{'menu.aboutUs' | translate}}</a>

                                            </li>


                                            <li class="lvl1  visible" id="element1088" style="">

                                                <a href="#block1088" class="scroll">{{'menu.contacts' | translate}}</a>

                                            </li>


                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div class="image-container">
                        <img (click)="changeLang('en')" class="image-language" src="/assets/britain.jpg">
                        <a class="image-ref" (click)="changeLang('en')">ENG</a>
                        <div>/</div>
                        <img (click)="changeLang('ru')" class="image-language" src="/assets/russia.jpg">
                        <a class="image-ref" (click)="changeLang('ru')">RUS</a>
                    </div>
                </div>
            </div>

            <div class="container  hidden-xs">

                <div class="menu-slide-wrap">

                    <div style="width: 115px;"></div>
                    <div class="row">


                        <table class="menu-slide">
                            <tbody>
                            <tr>
                                <td class="left col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <a class="scroll" href="#body">


                                        <img class="img-responsive" src="/assets/sb(1).png">

                                    </a>
                                </td>
                                <td class="center col-lg-9 col-md-9 col-sm-9 col-xs-9">

                                    <div class="wrapper-main-menu">

                                        <table class="wrap-main-menu">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div class="burger" style=""><a data-button="menu-slide-open"></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ul class="nav main-menu-nav table-ouch">


                                                        <li class="lvl1  visible" id="element1101" style="">

                                                            <a href="#block1101"
                                                               class="scroll">{{'menu.partners' | translate}}</a>

                                                        </li>


                                                        <li class="lvl1 visible dropdown-menu-trigger" id="element1105"
                                                            style="">

                                                            <a href="#block1105"
                                                               class="scroll">{{'menu.brands' | translate}}</a>

                                                            <div class="dropdown-menu change-top">
                                                                <div class="column">
                                                                    <p class="header">Kids</p>
                                                                    <ul>
                                                                        <li><a href="#block1127"
                                                                               data-img="/content/media/brands_logos/trybeyond.jpg">Trybeyond</a>
                                                                        </li>
                                                                        <li><a href="#block1127"
                                                                               data-img="/content/media/brands_logos/birba.jpg">Birba</a>
                                                                        </li>
                                                                        <li><a href="#block1136"
                                                                               data-img="/content/media/brands_logos/tuctuc.jpg">TUC
                                                                            TUC</a></li>
                                                                        <!--<li><a href="#block1138" data-img="/content/media/brands_logos/canadahouse.jpg">Canada House</a></li>-->
                                                                    </ul>
                                                                </div>
                                                                <div class="column">
                                                                    <p class="header">Shoes</p>
                                                                    <ul>
                                                                        <li><a href="#block1109"
                                                                               data-img="/content/media/brands_logos/viking.png">Viking</a>
                                                                        </li>
                                                                        <li><a href="#block1105"
                                                                               data-img="/content/media/brands_logos/kickers.jpg">Kickers</a>
                                                                        </li>
                                                                        <li><a href="#block1107"
                                                                               data-img="/content/media/brands_logos/BLACK_COUGAR_logo_BW_Waterproof-EN.png">Cougar</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="column">
                                                                    <p class="header">Fashion</p>
                                                                    <ul>
                                                                        <li><a href="#block1112"
                                                                               data-img="/content/media/brands_logos/boxeur.jpg">Boxeur
                                                                            Des Rues</a></li>
                                                                        <li><a href="#block1118"
                                                                               data-img="/content/media/brands_logos/cmp.jpg">CMP</a>
                                                                        </li>
                                                                        <li><a href="#block1122"
                                                                               data-img="/content/media/brands_logos/hype logo.jpg">Hype</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="column">
                                                                    <p class="header">Sport/outdoor</p>
                                                                    <ul>
                                                                        <li><a href="#block1118"
                                                                               data-img="/content/media/brands_logos/cmp.jpg">CMP</a>
                                                                        </li>
                                                                        <li><a href="#block1120"
                                                                               data-img="/content/media/brands_logos/camelbak.jpg">Camelbak</a>
                                                                        </li>
                                                                        <!--<li><a href="#block1122" data-img="/content/media/brands_logos/aku.jpg">Aku</a></li>-->
                                                                        <li><a href="#block1143"
                                                                               data-img="/content/media/brands_logos/robens.png">Robens</a>
                                                                        </li>
                                                                        <li><a href="#block1143"
                                                                               data-img="/content/media/brands_logos/easy_camp.png">Easy
                                                                            Camp</a></li>
                                                                        <li><a href="#block1143"
                                                                               data-img="/content/media/brands_logos/outwell.png">Outwell</a>
                                                                        </li>

                                                                    </ul>
                                                                </div>

                                                                <div class="image">
                                                                    <img alt="" class="brand-image"
                                                                         src="/assets/super_brands_logo_black_round.png">
                                                                </div>
                                                            </div>
                                                        </li>


                                                        <li class="lvl1  visible" id="element1081" style="">

                                                            <a href="#block1081"
                                                               class="scroll">{{'menu.offer' | translate}}</a>

                                                        </li>


                                                        <li class="lvl1  visible" id="element1131" style="">

                                                            <a href="#block1131"
                                                               class="scroll">{{'menu.aboutUs' | translate}}</a>

                                                        </li>


                                                        <li class="lvl1  visible" id="element1088" style="">

                                                            <a href="#block1088"
                                                               class="scroll">{{'menu.contacts' | translate}}</a>

                                                        </li>


                                                    </ul>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                </td>


                                <td class="right col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <a class="callback form primary" data-target=".callback-form"
                                       data-header="Шапка сайта (меню)" data-toggle="modal"></a>
                                </td>


                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="image-container">
                        <img (click)="changeLang('en')" class="image-language" src="/assets/britain.jpg">
                        <a class="image-ref" (click)="changeLang('en')">ENG</a>
                        <div>/</div>
                        <img (click)="changeLang('ru')" class="image-language" src="/assets/russia.jpg">
                        <a class="image-ref" (click)="changeLang('ru')">RUS</a>
                    </div>
                </div>
            </div>


        </div>

    </header>


    <div class="first-slider" id="block1162">


        <div *ngFor="let bannerUrl of banners" class="first-block     cover"
             style="background-image: url({{bannerUrl}});
">


            <div class="shadow"></div>

            <div class="container">
                <div class="row">


                    <div class="first-block-container light">

                        <div class="first-block-cell text-part col-lg-12 col-md-12 col-sm-12 col-xs-12">

                            <div class="">

                                <div class="head no-margin-top-bot ">


                                    <div class="icons row">


                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 element">

                                            <div class="icon">


                                                <div class="image-table">
                                                    <div class="image-cell">


                                                    </div>
                                                </div>


                                                <div class="text-wrap no-margin-top-bot">
                                                    <div class="text">
                                                    </div>
                                                </div>


                                            </div>


                                        </div>


                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>
            <div class="wrap-down hidden-xs">
                <div class="down-scroll">
                    <i class="fa fa-chevron-down"></i>
                </div>
            </div>

        </div>


    </div>


    <div id="block1100" class="block   padding-on
         " style="background-color: #ffffff;margin-bottom: -40px;padding-top: 40px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 dark">

                        {{'description' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1081" class="block   padding-on    cover
         " style="background-image: url(&#39;/upload/iblock/890/iStock-523200295.jpg&#39;);margin-top: -50px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 light">

                        {{'offer.title' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="info-num col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix ">
                        <div class="row">


                            <div class="info-num-element no-margin-top-bot col-lg-3 col-md-3 col-sm-6 col-xs-12 four-elements  light ">


                                <div class="title main1" style="font-size: 26px; line-height: 29px; min-height: 29px ">

                                    {{'offer.assortmentTitle' | translate}}
                                </div>


                                <div class="text">
                                    {{'offer.assortmentDescription' | translate}}
                                </div>
                            </div>


                            <div class="info-num-element no-margin-top-bot col-lg-3 col-md-3 col-sm-6 col-xs-12 four-elements  light ">


                                <div class="title main1" style="font-size: 26px; line-height: 29px; min-height: 29px ">

                                    {{'offer.profitableTermsTitle' | translate}}
                                </div>


                                <div class="text">
                                    {{'offer.profitableDescription' | translate}}
                                </div>
                            </div>


                            <span class="clearfix visible-sm"></span>


                            <div class="info-num-element no-margin-top-bot col-lg-3 col-md-3 col-sm-6 col-xs-12 four-elements  light ">


                                <div class="title main1" style="font-size: 26px; line-height: 29px; min-height: 29px ">

                                    {{'offer.freeStockTitle' | translate}}
                                </div>


                                <div class="text">
                                    {{'offer.freeStockDescription' | translate}}

                                </div>
                            </div>


                            <div class="info-num-element no-margin-top-bot col-lg-3 col-md-3 col-sm-6 col-xs-12 four-elements  light ">


                                <div class="title main1" style="font-size: 26px; line-height: 29px; min-height: 29px ">

                                    {{'offer.trainingTitle' | translate}}
                                </div>


                                <div class="text">
                                    {{'offer.trainingDescription' | translate}}

                                </div>
                            </div>


                            <span class="clearfix visible-sm"></span>

                            <span class="clearfix visible-lg visible-md"></span>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1101" class="block   padding-on
         " style="background-color: #ffffff;padding-top: -50px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <div class="descrip dark">{{'offer.description' | translate}}
                    </div>

                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="advantages clearfix images ">

                        <div class="advantages-table clearfix">

                            <div class="advantages-cell clearfix text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                    <div class="part-wrap row clearfix ">


                                    </div>


                                </div>


                            </div>


                        </div>


                    </div><!-- ^advantages -->


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1102" class="block   padding-on
         " style="background-color: #ffffff;margin-bottom: 50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                    <div class="text-wrap text-content no-margin-top-bot dark center">
                                        <img alt="партнеры.jpg" src="/assets/партнеры.jpg"
                                             title="партнеры.jpg"><br></div>


                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1103" class="block   padding-on   dark cover
         "
         style="background-image: url(&#39;/upload/iblock/743/iStock-894412732.jpg&#39;);background-color: #ffffff;background-attachment: fixed;margin-bottom: -50px;padding-top: 50px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 light">

                        {{'choose.description' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1104" class="block   padding-on
         " style="background-color: #eeeeee;margin-bottom: 30px;padding-top: 30px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 dark">

                        {{'choose.title'  | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="advantages clearfix images ">

                        <div class="advantages-table clearfix">

                            <div class="advantages-cell clearfix text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                    <div class="part-wrap row clearfix ">


                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                                            <div class="element  dark big">


                                                <div class="image-table">
                                                    <div class="image-cell">


                                                        <img alt="" class="img-responsive" src="/assets/3.png">


                                                    </div>
                                                </div>


                                                <div class="text-wrap no-margin-top-bot  icons-on">


                                                    <div class="name main1">
                                                        {{'choose.individualApproachTitle' | translate}}
                                                    </div>


                                                    <div class="text">
                                                        {{'choose.individualApproachDescription' | translate}}

                                                    </div>


                                                </div>


                                            </div>

                                        </div>


                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                                            <div class="element  dark big">


                                                <div class="image-table">
                                                    <div class="image-cell">


                                                        <img alt="" class="img-responsive"
                                                             src="/assets/1 — копия.png">


                                                    </div>
                                                </div>


                                                <div class="text-wrap no-margin-top-bot  icons-on">


                                                    <div class="name main1">
                                                        {{'choose.marketCoverageTitle' | translate}}
                                                    </div>


                                                    <div class="text">
                                                        {{'choose.marketCoverageDescription' | translate}}

                                                    </div>


                                                </div>


                                            </div>

                                        </div>


                                        <span class="clearfix visible-sm"></span>


                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                                            <div class="element  dark big">


                                                <div class="image-table">
                                                    <div class="image-cell">


                                                        <img alt="" class="img-responsive" src="/assets/2.png">


                                                    </div>
                                                </div>


                                                <div class="text-wrap no-margin-top-bot  icons-on">


                                                    <div class="name main1">
                                                        {{'choose.reliabilityTitle' | translate}}
                                                    </div>


                                                    <div class="text">
                                                        {{'choose.reliabilityDescription' | translate}}
                                                    </div>


                                                </div>


                                            </div>

                                        </div>


                                        <span class="clearfix hidden-sm"></span>


                                    </div>


                                </div>


                            </div>


                        </div>


                    </div><!-- ^advantages -->


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1109" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: -50px;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 "
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-right">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <a href="https://vikingfootwear.com/" target="_blank"><img
                                                src="/assets/df-format).png"></a><br>
                                        <br>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.vikingDescription1' | translate}}

                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.vikingDescription2' | translate}}

                                        </p>
                                        <p>
                                            <br>
                                        </p>
                                        <p>
                                            {{'brands.vikingDescription3' | translate}}

                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            &nbsp;
                                        </p>
                                        <img width="150" src="/assets/норвегия.jpg" height="50"> <a
                                            style="margin-left:10px;line-height:44px" href="https://vikingfootwear.com/"
                                            target="_blank"> Viking,&nbsp;Norway</a><br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12  middle">


                                <img alt="VIKING" class=" img-responsive center-block "
                                     src="/assets/IMG_2796.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1110" class="block   padding-on
         " style="margin-top: 50px;padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/d14/IMG_2897.jpg" data-gallery="gal1110" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/IMG_2897.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/f44/IMG_2862.jpg" data-gallery="gal1110" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/IMG_2862.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/400/IMG_2771.jpg" data-gallery="gal1110" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/IMG_2771.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1107" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-left">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <img width="614" alt="BLACK_COUGAR_logo_BW_Waterproof EN.png"
                                             src="/assets/BLACK_COUGAR_logo_BW_Waterproof EN.png" height="300"
                                             title="BLACK_COUGAR_logo_BW_Waterproof EN.png"><br>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.cougarDescription1' | translate}}
                                        </p>
                                        <p>
                                            <img width="150" alt="канада.jpg" src="/assets/канада.jpg"
                                                 height="44">&nbsp; <a href="http://cougarshoes.com/">&nbsp;Cougar</a><a
                                                href="http://cougarshoes.com/">,</a>&nbsp;<a
                                                href="http://cougarshoes.com/">C</a><a href="http://cougarshoes.com/">anada</a><br>
                                        </p></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 middle">


                                <img alt="KAMIK " class=" img-responsive center-block "
                                     src="/assets/cou-ss2020-lookbook-2019.09.18-page-05-1578593223229.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1108" class="block   padding-on
         " style="padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/d9f/24249-111065_1.jpg" data-gallery="gal1108"
                                   class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/24249-111065_1.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/dd3/24257-120065_1.jpg" data-gallery="gal1108"
                                   class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/24257-120065_1.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/8a4/29893-120327_1.jpg" data-gallery="gal1108"
                                   class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/29893-120327_1.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1105" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-bottom: 100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 "
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-right">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <a href="https://www.kickers.com/en/" target="_blank"><img
                                                alt="kickerslogoCMJNrouge.jpg" src="/assets/logo_kickers.jpg"></a><br>
                                        <p>
                                            {{'brands.kickersDescription1' | translate}}
                                            <br>
                                        </p>
                                        <img width="150" alt="франция.jpg" src="/assets/франция.jpg" height="50">
                                        <a style="margin-left:10px;line-height:44px" href="https://www.kickers.com/en/"
                                           target="_blank"> Kickers,&nbsp;France</a><br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12  middle">


                                <img alt="KICKERS" class=" img-responsive center-block "
                                     src="/assets/30c916517_456y89100807g6086_6815475601051222016_n.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1106" class="block   padding-on
         " style="margin-top: -150px;padding-top: -300px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/4ba/25011951_177118719692084_6624972210673025024_n.jpg"
                                   data-gallery="gal1106" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/25011951_177118719692084_6624972210673025024_n.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/14e/33062112_173253236674134_1787729555130155008_n.jpg"
                                   data-gallery="gal1106" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/33062112_173253236674134_1787729555130155008_n.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/91e/39251511_501679573645784_5486444369835720704_n.jpg"
                                   data-gallery="gal1106" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/39251511_501679573645784_5486444369835720704_n.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1111" class="block   padding-on   dark cover
         " style="background-image: url(&#39;/upload/iblock/40e/%D1%883446.jpg&#39;);background-attachment: fixed;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 light">

                        {{'taglines.tagline1' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="advantages clearfix images ">

                        <div class="advantages-table clearfix">

                            <div class="advantages-cell clearfix text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                    <div class="part-wrap row clearfix ">


                                    </div>


                                </div>


                            </div>


                        </div>


                    </div><!-- ^advantages -->


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1112" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: 50px;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-left">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <a href="https://www.boxeurdesrues.com/" target="_blank"><img width="700"
                                                                                                      alt="boxuer_des_rues_logo.png"
                                                                                                      src="/assets/boxuer_des_rues_logo.png"
                                                                                                      height="217"></a><br>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.boxeurDescription1' | translate}}&nbsp; &nbsp;&nbsp;<br>
                                        </p>
                                        <img alt="италия.jpg" src="/assets/италия.jpg"> <a target="_blank"
                                                                                           style="margin-left:10px;line-height:44px"
                                                                                           href="https://www.boxeurdesrues.com/">
                                        Boxeur&nbsp;des rues, Italy</a><br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 middle">


                                <img alt="BOXUER DES RUES" class=" img-responsive center-block "
                                     src="/assets/Boxeur - Group - 15.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1113" class="block   padding-on
         " style="margin-top: 50px;padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/5cd/Boxeur%20-%20Single%20-%206.jpg" data-gallery="gal1113"
                                   class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Boxeur - Single - 6.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/31a/1600_1200_140cd750bba9870f18aada2478b24840a/Boxeur%20-%20Single%20-%2022.jpg"
                                   data-gallery="gal1113" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Boxeur - Single - 22.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/d70/1600_1200_140cd750bba9870f18aada2478b24840a/Boxeur%20-%20Single%20-%2030.jpg"
                                   data-gallery="gal1113" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Boxeur - Single - 30.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1152" class="block
         " style="background-attachment: fixed;margin-top: -50px;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                                 style="">

                                <div class="wrap-padding-left">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        &nbsp;<img width="572" alt="RR лого.jpg" src="/assets/RR лого.jpg"
                                                   height="263" title="RR лого.jpg">
                                        <p style="text-align: justify;">
                                            {{'brands.royalRabbitDescription1' | translate}}
                                        </p>
                                        <br>
                                        <img width="150" alt="Мелкий флаг ВБ.jpg" src="/assets/Мелкий флаг ВБ.jpg"
                                             height="44" title="Мелкий флаг ВБ.jpg">&nbsp;&nbsp;&nbsp;Royal Rabbit,
                                        UK<br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 bottom">


                                <img alt="Royal Rabbit" class=" img-responsive center-block "
                                     src="/assets/30916517_45g689100807g6086_681547560151222016_n.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1156" class="block   padding-on
         " style="margin-top: 50px;margin-bottom: 50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix  dark ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/b95/1.jpg" data-gallery="gal1156" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/1.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/166/2.jpg" data-gallery="gal1156" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/2.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/a15/3.jpg" data-gallery="gal1156" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/3.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1127" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: 50px;margin-bottom: 50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 "
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-right">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <img width="1023" alt="LOGO BIRBA.jpg" src="/assets/LOGO BIRBA.jpg"
                                             height="274" title="LOGO BIRBA.jpg"><br>
                                        <br>
                                        <p>
                                            {{'brands.trybeyoundDescription1' | translate}}&nbsp; &nbsp;&nbsp;<br>
                                        </p>
                                        <img width="150" alt="италия.jpg" src="/assets/италия.jpg" height="44"> <a
                                            target="_blank" style="margin-left:10px;line-height:44px"
                                            href="https://www.birba-trybeyond.com/">Trybeyond&amp;Birba, Italy</a><br>
                                    </div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12  middle">


                                <img alt="TRYBEYOND &amp; BIRBA" class=" img-responsive center-block "
                                     src="/assets/DSC_3958.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1128" class="block   padding-on
         " style="margin-top: -100px;padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/465/1600_1200_140cd750bba9870f18aada2478b24840a/DSC_7159.jpg"
                                   data-gallery="gal1128" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/DSC_7159.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/0a9/DSC_4722.jpg" data-gallery="gal1128" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/DSC_4722.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/767/1600_1200_140cd750bba9870f18aada2478b24840a/DSC_7365.jpg"
                                   data-gallery="gal1128" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/DSC_7365.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1136" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: 50px;margin-bottom: 50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                                 style="">

                                <div class="wrap-padding-left">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <a href="https://www.tuctuc.com/uk/" target="_blank"><img width="1024"
                                                                                                  alt="logo_tuctuc_.png"
                                                                                                  src="/assets/y Profile tuctuc_EN-2.jpg"
                                                                                                  height="82"></a><br>
                                        <p>
                                            {{'brands.tucTucDescription1' | translate}}&nbsp;
                                        </p>
                                        <img width="150" alt="spain.jpg" src="/assets/испания.jpg" height="44"> <a
                                            target="_blank" style="margin-left:10px;line-height:44px"
                                            href="https://www.tuctuc.com/uk/">{{'brands.tucTucTitle' | translate}}</a><br>
                                    </div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 middle">


                                <img alt="tuc tuc" class=" img-responsive center-block "
                                     src="/assets/TucTuc_2019_06_041607 копия.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1137" class="block   padding-on
         " style="margin-top: -100px;padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/8b9/TucTuc_2019_06_042197%20%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.jpg"
                                   data-gallery="gal1137" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/TucTuc_2019_06_042197 копия.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/d3f/TucTuc_2019_06_041258%20%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.jpg"
                                   data-gallery="gal1137" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/TucTuc_2019_06_041258 копия.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/0f3/TucTuc_2019_06_041527%20%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.jpg"
                                   data-gallery="gal1137" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/TucTuc_2019_06_041527 копия.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1126" class="block   padding-on    cover
         "
         style="background-image: url(&#39;/upload/iblock/f8c/iStock-529931517_.jpg&#39;);background-color: #f0f0f0;background-attachment: fixed;margin-bottom: 50px;padding-top: 50px;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 light">

                        {{'taglines.tagline2' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1116" class="block   padding-on  video-fon  cover
         " style="margin-bottom: 150px;padding-top: 550px;">


        <div class="video-background" data-video="G99FEYxxkLk">
            <iframe class="video-inner" style="width: 1625px; height: 914.062px;" id="player_bg0" frameborder="0"
                    allowfullscreen="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    title="URBAN V4" width="640" height="360"
                    src="https://www.youtube.com/embed/G99FEYxxkLk?autoplay=1&controls=0&disablekb=0&iv_load_policy=3&modestbranding=0&showinfo=0&rel=0&loop=1&playlist=G99FEYxxkLk&enablejsapi=1&origin=http%3A%2F%2Feng.superbrands.ru&widgetid=1"></iframe>
            <div class="video-background-over"></div>
        </div>


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 style="">

                                <div class="">


                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1118" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: -100px;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 "
                                 style="">

                                <div class="wrap-padding-right">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <a href="https://www.cmpsport.com/en_ww" target="_blank"><img width="1023"
                                                                                                      alt="Logo_CMP.jpg"
                                                                                                      src="/assets/Logo_CMP.jpg"
                                                                                                      height="314"></a><br>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.cmpDescription1' | translate}}.&nbsp; &nbsp;&nbsp;<br>
                                        </p>
                                        <img width="150" alt="италия.jpg" src="/assets/италия.jpg" height="44"> <a
                                            target="_blank" style="margin-left:10px;line-height:44px"
                                            href="https://www.cmpsport.com/en_ww"> {{'brands.cmpTitle' | translate}}</a>
                                    </div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12  middle">


                                <img alt="CMP" class=" img-responsive center-block "
                                     src="/assets/DM_STLABS_CMP Tonale SS20_20190912_16009.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1119" class="block   padding-on
         " style="padding-top: -150px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/bb7/1600_1200_140cd750bba9870f18aada2478b24840a/DM_STLABS_CMP%20Tonale%20SS20_20190912_13666.jpg"
                                   data-gallery="gal1119" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/DM_STLABS_CMP Tonale SS20_20190912_13666.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/5c7/1600_1200_140cd750bba9870f18aada2478b24840a/DM_STLABS_CMP%20Tonale%20SS20_20190911_00097.jpg"
                                   data-gallery="gal1119" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/DM_STLABS_CMP Tonale SS20_20190911_00097.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/0ff/1600_1200_140cd750bba9870f18aada2478b24840a/DM_STLABS_CMP%20Tonale%20SS20_20190912_17932.jpg"
                                   data-gallery="gal1119" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/DM_STLABS_CMP Tonale SS20_20190912_17932.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1122" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-left">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <img width="572" alt="Логотип Hype.jpg" src="/assets/Логотип Hype.jpg"
                                             height="263" title="Логотип Hype.jpg"><br>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            &nbsp;
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.hypeDescription1' | translate}}<sub></sub><sup></sup><br>
                                        </p>
                                        <img alt="Мелкий флаг ВБ.jpg" src="/assets/Мелкий флаг ВБ.jpg"
                                             title="Мелкий флаг ВБ.jpg">&nbsp; &nbsp;<a href="http://justhype.co.uk/">Hype, </a><a
                                            href="http://justhype.co.uk/">{{'brands.hypeCountry' | translate}}</a><a
                                            href="http://justhype.co.uk/">&nbsp;</a><br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 middle">


                                <img alt="AKU " class=" img-responsive center-block "
                                     src="/assets/Основное фото Hype.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1123" class="block   padding-on
         " style="margin-top: -100px;margin-bottom: -100px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/1c2/Hype2.jpg" data-gallery="gal1123" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/Hype2.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/7e6/Hype3.jpg" data-gallery="gal1123" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/Hype3.jpg">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/iblock/281/Hype4.jpg" data-gallery="gal1123" class="cursor-loop"
                                   title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block" src="/assets/Hype4.jpg">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1143" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: 50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="descriptive">

                        <div class="descriptive-table">

                            <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 "
                                 style="padding-top: 100px;">

                                <div class="wrap-padding-right">


                                    <div class="text-wrap text-content no-margin-top-bot dark ">
                                        <img alt="z.jpg" src="/assets/z.jpg" title="z.jpg"><br>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                        </p>
                                        <p>
                                            {{'brands.easyCampDescription' | translate}}&nbsp;<br>
                                        </p>
                                        <p>
                                            {{'brands.robensDescription' | translate}}<br>
                                        </p>
                                        <p>
                                            {{'brands.outwellDescription' | translate}}<br>
                                        </p>
                                        <img src="/assets/дания_флаг.jpg"> <br></div>


                                    <div class="clearfix"></div>


                                </div>


                            </div>


                            <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12  middle">


                                <img alt="ROBENS" class=" img-responsive center-block "
                                     src="/assets/30c916517_4x56s89100807g6086_6815475601051222016_n копия.jpg">

                            </div>


                        </div>
                    </div>


                </div>


            </div>
        </div>


    </div>


    <div id="block1144" class="block   padding-on
         " style="margin-top: -50px;">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="gallery-block clearfix   ">


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/4fb/1600_1200_140cd750bba9870f18aada2478b24840a/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202019-02-07%20%D0%B2%2013.04.23.png"
                                   data-gallery="gal1144" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Снимок экрана 2019-02-07 в 13.04.23.png">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/aea/1600_1200_140cd750bba9870f18aada2478b24840a/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202019-02-07%20%D0%B2%2013.00.00.png"
                                   data-gallery="gal1144" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Снимок экрана 2019-02-07 в 13.00.00.png">


                                </a>

                            </div>


                        </div>


                        <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                            <div class="gallery-img">

                                <a href="/upload/resize_cache/iblock/c34/1600_1200_140cd750bba9870f18aada2478b24840a/%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202019-02-07%20%D0%B2%2013.03.26.png"
                                   data-gallery="gal1144" class="cursor-loop" title="">


                                    <div class="corner-line mainColor"></div>
                                    <img alt="" class="img-responsive center-block"
                                         src="/assets/Снимок экрана 2019-02-07 в 13.03.26.png">


                                </a>

                            </div>


                        </div>


                        <span class="clearfix visible-xs"></span>


                        <span class="clearfix hidden-xs"></span>


                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>


    <div id="block1149" class="block   padding-on
         " style="background-color: #ffffff;">


        <div class="shadow"></div>


        <div class="head   ">

            <div class="container">

                <div class="no-margin-top-bot">


                    <h2 class="main1 dark">

                        {{'assortment.title' | translate}}


                    </h2>


                </div>

            </div>

        </div>


        <div class="content ">

            <div class="container">


                <div class="row">


                    <div class="slider-advantages images big-slide clearfix dark">

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens10.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle1' | translate}}</div>

                                    <div class="desc">{{'assortment.description1' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens_1.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle2' | translate}}</div>

                                    <div class="desc">{{'assortment.description2' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens_3.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle3' | translate}}</div>

                                    <div class="desc">{{'assortment.description3' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens2.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle4' | translate}}</div>

                                    <div class="desc">{{'assortment.description4' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens4.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle6' | translate}}
                                    </div>

                                    <div class="desc">{{'assortment.description6' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens5.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle7' | translate}}
                                    </div>

                                    <div class="desc">{{'assortment.description7' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens6.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle5' | translate}}</div>

                                    <div class="desc">{{'assortment.description5' | translate}}</div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens7.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle8' | translate}}</div>

                                    <div class="desc">{{'assortment.description8' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens8.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle9' | translate}}</div>

                                    <div class="desc">{{'assortment.description9' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens9.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle10' | translate}}</div>

                                    <div class="desc">{{'assortment.description10' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens10.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle11' | translate}}</div>

                                    <div class="desc">{{'assortment.description11' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="div-table">


                                <div class="div-cell left">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <img src="/assets/ассортимент_robens_1.jpg"
                                                     class="img-responsive center-block" alt="">


                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="div-cell right">

                                    <div class="title bold">{{'assortment.subtitle12' | translate}}</div>

                                    <div class="desc">{{'assortment.description12' | translate}}
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>



            <div class="clearfix"></div>


        </div>
    </div>


</div>


<div id="block1120" class="block      cover
         " style="background-color: #ffffff;background-attachment: fixed;margin-top: 50px;margin-bottom: -100px;">


    <div class="shadow"></div>


    <div class="content no-margin">

        <div class="container">


            <div class="row">


                <div class="descriptive">

                    <div class="descriptive-table">

                        <div class="descriptive-cell text-part z-text col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0 right"
                             style="padding-top: 100px;">

                            <div class="wrap-padding-left">


                                <div class="text-wrap text-content no-margin-top-bot dark ">
                                    <a href="https://international.camelbak.com/" target="_blank"><img width="1024"
                                                                                                       alt="logo_camelback.png"
                                                                                                       src="/assets/logo_camelback.png"
                                                                                                       height="269"></a><br>
                                    <p>
                                    </p>
                                    <p>
                                    </p>
                                    <p>
                                    </p>
                                    <p>
                                        {{'brands.camelBackDescription1' | translate}}&nbsp;&nbsp;<br>
                                    </p>
                                    <img width="150" alt="usa_флаг.jpg" src="/assets/usa_флаг.jpg"
                                         height="44"> <a target="_blank" style="margin-left:10px;line-height:44px"
                                                         href="https://international.camelbak.com/">{{'brands.camelBackTitle' | translate}}</a>
                                </div>


                                <div class="clearfix"></div>


                            </div>


                        </div>


                        <div class="descriptive-cell image-part z-image col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0 middle">


                            <img alt="CAMELBAK" class=" img-responsive center-block "
                                 src="/assets/bottles_hero_2.jpg">

                        </div>


                    </div>
                </div>


            </div>


        </div>
    </div>


</div>


<div id="block1121" class="block   padding-on
         " style="margin-top: 50px;padding-top: -150px;">


    <div class="shadow"></div>


    <div class="content no-margin">

        <div class="container">


            <div class="row">


                <div class="gallery-block clearfix   ">


                    <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                        <div class="gallery-img">

                            <a href="/upload/iblock/6e1/_DSC5728.jpg" data-gallery="gal1121" class="cursor-loop"
                               title="">


                                <div class="corner-line mainColor"></div>
                                <img alt="" class="img-responsive center-block" src="/assets/_DSC5728.jpg">


                            </a>

                        </div>


                    </div>


                    <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                        <div class="gallery-img">

                            <a href="/upload/iblock/540/festival_story_callout.jpg" data-gallery="gal1121"
                               class="cursor-loop" title="">


                                <div class="corner-line mainColor"></div>
                                <img alt="" class="img-responsive center-block"
                                     src="/assets/festival_story_callout.jpg">


                            </a>

                        </div>


                    </div>


                    <div class="col-lg-4 col-md-4 col-sm-4  col-xs-4 big">


                        <div class="gallery-img">

                            <a href="/upload/resize_cache/iblock/9d7/1600_1200_140cd750bba9870f18aada2478b24840a/_DSC8097.jpg"
                               data-gallery="gal1121" class="cursor-loop" title="">


                                <div class="corner-line mainColor"></div>
                                <img alt="" class="img-responsive center-block" src="/assets/_DSC8097.jpg">


                            </a>

                        </div>


                    </div>


                    <span class="clearfix visible-xs"></span>


                    <span class="clearfix hidden-xs"></span>


                </div>


            </div>


            <div class="clearfix"></div>


        </div>
    </div>


</div>


<!--
    <div id="block1130" class="block   padding-on    cover
         " style="background-image: url(&#39;/upload/iblock/305/1.jpg&#39;);">


        <div class="shadow"></div>


        <div class="content no-margin">

            <div class="container">


                <div class="row">


                    <div class="form-block  un-margin-bottom">


                        <div class="form-table">


                            <div class="form-cell text-part z-text" style="">
                                <div class="">


                                    <form action="/" class="form send" enctype="multipart/form-data" method="post"
                                          role="form" style="background-image: url(&#39;&#39;);">

                                        <input id="section" name="section" type="hidden" value="153">
                                        <input id="element" name="element" type="hidden" value="1130">
                                        <input id="site_id" name="site_id" type="hidden" value="s1">
                                        <input id="url" name="url" type="hidden" value="/">
                                        <input id="header" name="header" type="hidden" value="JOIN US ">


                                        <input type="hidden" name="form_admin" id="form_admin" value="light">


                                        <table class="wrap-act">
                                            <tbody>
                                            <tr>
                                                <td>

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 questions active ">


                                                        <div class="title main1">
                                                            JOIN US TODAY
                                                        </div>


                                                        <div class="subtitle">
                                                            And start earning tomorrow
                                                        </div>


                                                        <div class="row">


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">


                                                            </div>


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input">
                                                                    <div class="bg"></div>
                                                                    <span class="desc">Name</span>
                                                                    <input class="focus-anim require" id="name"
                                                                           name="name" type="text">

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input">

                                                                    <input class="phone require"
                                                                           data-placeholder="Телефон" id="phone"
                                                                           name="phone" placeholder="Phone" type="text">
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input">
                                                                    <div class="bg"></div>

                                                                    <span class="desc">E-mail</span>
                                                                    <input class="focus-anim email " id="email"
                                                                           name="email" type="text">

                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input">
                                                                    <textarea data-placeholder="Комментарий" id="text"
                                                                              name="text"
                                                                              placeholder="Comment"></textarea>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="input-btn">
                                                                    <div class="load">
                                                                        <div id="xLoader" class="form-preload">
                                                                            <div class="audio-wave">
                                                                                <span></span><span></span><span></span><span></span><span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button class="button-def primary big active elips"
                                                                            id="form-submit" name="form-submit"
                                                                            type="submit">

                                                                        Send

                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 thank">
                                                        Thank you
                                                    </div>

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 timeout_text">
                                                        Время вышло, вы не успели
                                                    </div>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>


                                        <div class="clearfix">
                                        </div>
                                    </form>

                                </div>


                            </div>


                        </div>

                    </div>


                </div>


                <div class="clearfix"></div>


            </div>
        </div>


    </div>

-->

<div id="block1131" class="block   padding-on
         " style="background-color: #ffffff;">


    <div class="shadow"></div>


    <div class="content no-margin">

        <div class="container">


            <div class="row">


                <div class="opinion">

                    <div class="opinion-table">

                        <div class="opinion-cell text-part no-margin-top-bot col-lg-7 col-md-7 col-sm-8 col-xs-12 col-lg-push-5 col-md-push-5 col-sm-push-4 col-xs-push-0">


                            <div class="head  min ">


                                <div class="no-margin-top-bot">


                                    <h2 class="main1 dark">

                                        {{'aboutUs.title' | translate}}


                                    </h2>


                                </div>


                            </div>


                            <div class="text no-margin-top-bot italic">
                                {{'aboutUs.description' | translate}}
                            </div>


                        </div>


                        <div class="opinion-cell z-image image-part hidden-xs col-lg-5 col-md-5 col-sm-4 col-xs-12 col-lg-pull-7 col-md-pull-7 col-sm-pull-8 col-xs-pull-0">

                            <img alt="" class="img-responsive center-block"
                                 src="/assets/super_brands_logo_black_round(1).png">


                        </div>


                    </div>
                </div>


            </div>


        </div>
    </div>


</div>


<div id="block1088" class="block   padding-on
         " style="padding-top: 0px;padding-bottom: 0px;">


    <div class="shadow"></div>


    <div class="content no-margin">

        <div class="container">


            <div class="row">


            </div>
        </div>


        <div class="map-block ">


            <div class="map-descript-wrap">

                <div class="container">
                    <div class="row">


                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">

                            <table class="wrap-table">
                                <tbody>
                                <tr>
                                    <td>

                                        <div class="map-descript">


                                            <div class="name">
                                                {{'showroom' | translate}}
                                            </div>


                                            <div class="text-table-wrap">


                                                <div class="text-table">
                                                    <div class="text-cell icon icon-point"></div>

                                                    <div class="text-cell text">

                                                        {{'address' | translate}}
                                                    </div>
                                                </div>


                                                <div class="text-table">
                                                    <div class="text-cell icon icon-phone">
                                                    </div>


                                                    <div class="text-cell text phone bold">


                                                        <a href="tel:+74951203434">+7 495 120 34 34</a>


                                                    </div>
                                                </div>


                                                <div class="text-table">
                                                    <div class="text-cell icon icon-mail">
                                                    </div>


                                                    <div class="text-cell text e-mail">


                                                        <a href="mailto:info@superbrands.ru">info@superbrands.ru</a>


                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </td>
                                </tr>
                                </tbody>
                            </table>


                        </div>

                        <span class="clearfix">  </span>


                    </div>
                </div>

            </div>


            <div class="container">

                <div class="main-button-wrap center">

                    <a class="map-show button-def secondary ">Show map</a>

                </div>

            </div>


            <div class="map-height">


                <div class="overlay" onclick="style.pointerEvents='none'"></div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2242.3570133192497!2d37.57967236203265!3d55.80440215914851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6d713ef6eb6a57e!2z0JHQuNC30L3QtdGBLdGG0LXQvdGC0YAg0JTQuNCw0LPQvtC90LDQu9GMINCl0LDRg9GB!5e0!3m2!1sru!2sru!4v1542179655003"
                        width="600" height="450" frameborder="0" style="border:0"
                        allowfullscreen=""></iframe>

            </div>


        </div>

        <div>
            <div>


            </div>


            <div class="clearfix"></div>


        </div>
    </div>


</div>


<footer class="tone-light">
    <div class="container">


        <img src="/assets/sb.png" class="footer-logotype-bg img-responsive"
             style="height: 285.571px; width: 100%;">

        <div class="footer-content-wrap no-margin-top-bot">


            <div class="logotype">
                <img class="img-responsive center-block" src="/assets/sb(1).png">
            </div>


            <div class="contacts-table-wrap">
                <div class="contacts-table">

                    <div class="contacts-cell number main1">
                        +7 495 120 34 34
                    </div>


                    <div class="contacts-cell email">
                        <a href="mailto:info@superbrands.ru">info@superbrands.ru</a>
                    </div>


                </div>
            </div>


        </div>
    </div>


    <div class="footer-reqs">

        <div class="container">
            {{'footer.description' | translate}}<br> © {{'footer.copyright' | translate}}
        </div>

    </div>


</footer>

<a href="#body" class="up scroll"></a>


</div>

<div class="no-click-block"></div>

<div class="wrap-modal">

    <div class="scroll-close">
        <div class="container row">
            <a class="wrap-modal-close"></a>
        </div>
    </div>

    <div class="modal-container"></div>


</div>


<div class="modal fade form-modal modal-catalog" aria-hidden="true" aria-labelledby="mySmallModalLabel">

    <div class="click-for-reset"></div>

    <div class="modal-dialog">

        <a aria-hidden="true" class="form-close" data-dismiss="modal" type="button"></a>

        <form action="/" class="form send" method="post" role="form">
            <input id="site_id" name="site_id" type="hidden" value="s1">
            <input id="comment" name="comment" type="hidden" value="">
            <input id="url" name="url" type="hidden" value="/">
            <input id="header" name="header" type="hidden" value="">
            <input id="section" name="section" type="hidden" value="153">
            <input id="hameleon-id" name="hameleon-id" type="hidden" value="hameleon-catalog-order">
            <input id="other_comp" name="other_comp" type="hidden" value="">

            <input id="send_y" name="send_y" type="hidden" value="Y">

            <table class="wrap-act">
                <tbody>
                <tr>
                    <td>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 questions active">

                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 title-form main1">call back</div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 subtitle-form">
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input count">
                                        <input data-placeholder="Количество" id="count" name="count"
                                               placeholder="Количество" type="text"> <span class="plus"></span> <span
                                            class="minus"></span>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input">
                                        <div class="bg"></div>
                                        <span class="desc">Name</span>
                                        <input class="focus-anim" id="name" name="name" type="text">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input">

                                        <input class="phone require" data-placeholder="Телефон" id="phone" name="phone"
                                               placeholder="Phone" type="text">

                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input">
                                        <textarea data-placeholder="Комментарий" id="text" name="text"
                                                  placeholder="Комментарий"></textarea>
                                    </div>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input-btn">
                                        <div class="load">
                                            <div id="xLoader" class="form-preload">
                                                <div class="audio-wave">
                                                    <span></span><span></span><span></span><span></span><span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button-def primary big active elips" id="form-submit"
                                                name="form-submit" type="submit">Send
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 thank">Thank you! Your request has been
                            submitted.
                        </div>

                        <div class="clearfix">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

        </form>

    </div>
</div>


<div class="modal fade form-modal callback-form" aria-hidden="true" aria-labelledby="mySmallModalLabel">

    <div class="click-for-reset"></div>

    <div class="modal-dialog">

        <a aria-hidden="true" class="form-close" data-dismiss="modal" type="button"></a>

        <form action="/" class="form send" method="post" role="form">
            <input id="site_id" name="site_id" type="hidden" value="s1">
            <input id="url" name="url" type="hidden" value="/">
            <input id="section" name="section" type="hidden" value="153">
            <input id="header" name="header" type="hidden" value="">
            <input id="hameleon-id" name="hameleon-id" type="hidden" value="hameleon-callback">
            <input id="comment" name="comment" type="hidden" value="">
            <input id="other_comp" name="other_comp" type="hidden" value="">

            <input id="send_y" name="send_y" type="hidden" value="Y">


            <table class="wrap-act">
                <tbody>
                <tr>
                    <td>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 questions active">

                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 title-form main1">call back</div>


                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input">
                                        <div class="bg"></div>
                                        <span class="desc">Name</span>
                                        <input class="focus-anim" id="name" name="name" type="text">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input">

                                        <input class="phone require" data-placeholder="Телефон" id="phone" name="phone"
                                               placeholder="Phone" type="text">

                                    </div>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input-btn">
                                        <div class="load">
                                            <div id="xLoader" class="form-preload">
                                                <div class="audio-wave">
                                                    <span></span><span></span><span></span><span></span><span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="button-def primary big active elips" id="form-submit"
                                                name="form-submit" type="submit">Send
                                        </button>


                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 thank">Thank you! Your request has been
                            submitted.
                        </div>


                        <div class="clearfix">
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>


        </form>

    </div>
</div>


<div class="modal fade wind-modal wind-modalphones">

    <div class="click-for-reset"></div>

    <div class="modal-dialog">
        <a aria-hidden="true" class="form-close" data-dismiss="modal" type="button"></a>

        <div class="wind-content">

            <div class="list-contacts-modal">
                <table>


                    <tbody>
                    <tr>
                        <td>
                            <div class="phone bold">+7 495 120 34 34</div>

                            <div class="desc">MOSCOW, BUTYRSKAYA 77</div>
                        </td>
                    </tr>


                    <tr>
                        <td>

                            <div class="email">
                                <a href="mailto:info@superbrands.ru">info@superbrands.ru</a>
                            </div>

                            <div class="desc">Office</div>
                        </td>
                    </tr>


                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>


<div class="shadow-agree"></div>


<script type="text/javascript">
    var tag = document.createElement('script');
    tag.src = "/bitrix/templates/concept_hameleon/js/video.js";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
</script>


<div class="blueimp-gallery blueimp-gallery-controls" id="blueimp-gallery">
    <div class="slides"></div>
    <h3 class="title"></h3>
    <a class="prev"></a>
    <a class="next"></a>
    <a class="close"></a>
</div>


<script>(function () {
    const DROPDOWN_MENU_CLASS = "dropdown-menu";
    const DROPDOWN_MENU_BTN_ID = "element1105"

    document.addEventListener('DOMContentLoaded', init);

    function init() {
        var menuBtns = document.querySelectorAll("#" + DROPDOWN_MENU_BTN_ID);

        menuBtns[0].classList.add("dropdown-menu-trigger");
        var menu1 = createDropdownMenu();
        menuBtns[0].append(menu1);

        menuBtns[1].classList.add("dropdown-menu-trigger");
        var menu2 = createDropdownMenu();
        menu2.classList.add("change-top");
        menuBtns[1].append(menu2);

        addSmoothScroll();

        // addTranslation();

        // addTranslationToMobile();

        translateForm();
    }

    function translateForm() {
        document.querySelector("#block1130 #name").previousSibling.previousSibling.textContent = "Name";
        document.querySelector("#block1130 #phone").placeholder = "Phone";
        document.querySelector("#block1130 #text").placeholder = "Comment";


        document.querySelectorAll(".modal-dialog form .wrap-act #name").forEach(n => n.previousSibling.previousSibling.textContent = "Name");
        document.querySelectorAll(".modal-dialog form .wrap-act #phone").forEach(n => n.placeholder = "Phone");
        document.querySelectorAll(".modal-dialog form .wrap-act .title-form").forEach(n => n.textContent = "call back");
        document.querySelectorAll(".modal-dialog form .wrap-act #form-submit").forEach(n => n.textContent = "Send");
        document.querySelectorAll(".modal-dialog form .wrap-act .thank").forEach(n => n.textContent = "Thank you! Your request has been submitted.");

        document.querySelector("a.map-show").textContent = "Show map";
        document.querySelector("a.button-def.primary.big.form.elips").text = "Call back";
    }

    function addSmoothScroll() {
        var refs = document.querySelectorAll(".dropdown-menu .column li a");
        for (var i = 0; i < refs.length; i++) {
            refs[i].addEventListener("click", function (event) {
                event.preventDefault();

                var block = document.querySelector(this.getAttribute('href'));

                $('body,html').animate({scrollTop: block.offsetTop}, 1000);
            });
        }
    }

    function addTranslation() {
        addTranslationForFixedMenu();
        addTranslationNonFixedMenu();
    }

    function addTranslationToMobile() {
        var menu = document.querySelector("div.slide-menu .nav");

        var eng = document.createElement("li");
        var rus = document.createElement("li");
        eng.innerHTML =
            `<a (click)="changeLang('en')" class="scroll" data-button="close-from-menu">
        <span>ENGLISH VERSION</span>
        </a>`;
        rus.innerHTML =
            `<a (click)="changeLang('ru')" class="scroll" data-button="close-from-menu">
        <span>RUSSIAN VERSION</span>
        </a>`;
        menu.appendChild(eng);
        menu.appendChild(rus);
    }

    function addTranslationForFixedMenu() {
        var menuRow = document.querySelector("header.tone-light.menu-open div.scroll-wrap div.menu-type2.ln.active .container");
        var menu = document.querySelector("header.menu-open div.scroll-wrap div.menu-type3")

        var blank = document.createElement("div");
        blank.style.width = "115px";

        menuRow.insertBefore(blank, menu);
        menuRow.appendChild(flag);

        var elems = document.querySelectorAll("header.tone-light.menu-open div.scroll-wrap div.menu-type2.ln.active .container .image-language");
        elems[0].addEventListener("click", function () {
            window.location.href = ("http://eng.superbrands.ru");
        });
        elems[1].addEventListener("click", function () {
            window.location.href = ("http://superbrands.ru");
        })
    }

    function addTranslationNonFixedMenu() {
        var menuRow = document.querySelector("header.menu-open div.scroll-wrap div.menu-slide-wrap");
        var row = document.querySelector("header.tone-light.slide div.scroll-wrap .menu-slide-wrap .row");

        var blank = document.createElement("div");
        blank.style.width = "115px";


        var flag = document.createElement("div");
        flag.className = "image-container";

        flag.innerHTML = `
        <img href="http://eng.superbrands.ru" class="image-language" src="/content/media/flags/britain.jpg">
        <a class="image-ref" href="http://eng.superbrands.ru">ENG</a>
        <div>/</div>
        <img href="http://superbrands.ru" class="image-language" src="/content/media/flags/russia.jpg">
        <a class="image-ref" href="http://superbrands.ru">RUS</a>
        `;

        menuRow.appendChild(flag);

        var elems = document.querySelectorAll("header.menu-open div.scroll-wrap div.menu-slide-wrap .image-language");
        elems[0].addEventListener("click", function () {
            window.location.href = ("http://eng.superbrands.ru");
        });
        elems[1].addEventListener("click", function () {
            window.location.href = ("http://superbrands.ru");
        });
        console.dir(row);
        console.dir(menuRow)
        menuRow.insertBefore(blank, row);
    }

    function createDropdownMenu() {
        var dropContainer = document.createElement("div");
        dropContainer.classList.add(DROPDOWN_MENU_CLASS);
        dropContainer.innerHTML = `
         <div class="column">
            <p class="header">Kids</p>
            <ul>
                <li><a href="#block1127" data-img="/content/media/brands_logos/trybeyond.jpg">Trybeyond</a></li>
                <li><a href="#block1127" data-img="/content/media/brands_logos/birba.jpg">Birba</a></li>
                <li><a href="#block1136" data-img="/content/media/brands_logos/tuctuc.jpg">TUC TUC</a></li>
                <!--<li><a href="#block1138" data-img="/content/media/brands_logos/canadahouse.jpg">Canada House</a></li>-->
            </ul>
        </div>
        <div class="column">
            <p class="header">Shoes</p>
            <ul>
                <li><a href="#block1109" data-img="/content/media/brands_logos/viking.png">Viking</a></li>
                <li><a href="#block1105" data-img="/content/media/brands_logos/kickers.jpg">Kickers</a></li>
                <li><a href="#block1107" data-img="/content/media/brands_logos/BLACK_COUGAR_logo_BW_Waterproof-EN.png">Cougar</a></li>
            </ul>
        </div>
        <div class="column">
            <p class="header">Fashion</p>
            <ul>
                <li><a href="#block1112" data-img="/content/media/brands_logos/boxeur.jpg">Boxeur Des Rues</a></li>
                <li><a href="#block1118" data-img="/content/media/brands_logos/cmp.jpg">CMP</a></li>
                <li><a href="#block1122" data-img="/content/media/brands_logos/hype logo.jpg">Hype</a></li>
            </ul>
        </div>
        <div class="column">
            <p class="header">Sport/outdoor</p>
            <ul>
            <li><a href="#block1118" data-img="/content/media/brands_logos/cmp.jpg">CMP</a></li>
                <li><a href="#block1120" data-img="/content/media/brands_logos/camelbak.jpg">Camelbak</a></li>
                <!--<li><a href="#block1122" data-img="/content/media/brands_logos/aku.jpg">Aku</a></li>-->
                <li><a href="#block1143" data-img="/content/media/brands_logos/robens.png">Robens</a></li>
                <li><a href="#block1143" data-img="/content/media/brands_logos/easy_camp.png">Easy Camp</a></li>
                <li><a href="#block1143" data-img="/content/media/brands_logos/outwell.png">Outwell</a></li>

            </ul>
        </div>

        <div class="image">
            <img alt="" class="brand-image" src="/content/media/brands_logos/super_brands_logo_black_round.png">
        </div>
        `;


        var logoImg = dropContainer.querySelector(".image img");
        var defaultLogo = logoImg.src;

        dropContainer.addEventListener("mouseover", function (event) {
            if (event.target.nodeName !== "A")
                return;

            var target = event.target;
            var logo = target.dataset["img"];

            if (!logo || logo === "")
                return;

            logoImg.src = logo;

            var resetLogo = function () {
                logoImg.src = defaultLogo;
                target.removeEventListener("mouseleave", resetLogo);
            }

            target.addEventListener("mouseleave", resetLogo);
        })

        return dropContainer;
    }

})();</script>
